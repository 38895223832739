import axios from '@axios'
import qs from 'qs'

export default {
  namespaced: true,
  state: {
   resultProduce:'',
    condition: {
      produce_no:undefined,
      status:undefined,
      warehouse_id:undefined,
      applier_id:undefined,
      applier_name:undefined,
      warehouse_name:undefined,
      true_last_date:undefined,
      statusLabel:'生产中'
    }
  },
  getters: {
    getResultProduce(state){
      return state.resultProduce
    },
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    resetCondition(state){
      for (let conditionKey in state.condition) {
        state.condition[conditionKey] = undefined
      }
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/produceorder/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    produce(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/produceorder/produce', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    view(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/produceorder/view', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    edit(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .get('/api/produceorder/edit', {params: params})
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    save(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/save', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    state(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/produceorder/state', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    submitcheck(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/submitcheck', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitcheckproduct(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/submitcheckproduct', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitcheckno(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/submitcheckno', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitproductcheckok(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/submitproductcheckok', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    submitproductcheckno(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/submitproductcheckno', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    producecomplete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/producecomplete', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkcomplete(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/produceorder/checkcomplete', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  savelast(ctx, params) {
    return new Promise((resolve, reject) => {
      axios
          .post('/api/produceorder/savelast', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
    })
  },

    printProduceOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/produceorder/printProduceOrder', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },

    onlySave(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
            .post('/api/produceorder/onlySave', params)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },





  },
}
