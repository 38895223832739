<template>
  <b-card
    class="produceorder-edit-wrapper"
  >
   <!-- <validation-observer ref="simpleRules">-->
    <!-- form -->
    <b-form id="produceorderForm" class="edit-form mt-2">
      <b-card header="基本信息">
        <b-row>
          <!--生产编号-->
          <b-col md="3">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="指令编号"
                    label-for="produce_no"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="outbound_no"
                      size="sm"
                      v-model="produceorder.produce_no"
                      readonly
              />
            </b-form-group>
          </b-col>
          <!--申请人-->
          <b-col md="3">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="申请人"
                    label-for="applier_name"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="duty_name"
                      size="sm"
                      v-model="produceorder.applier_name"
                      @click="showPeopleModal()"
                      readonly
              />
            </b-form-group>
          </b-col>

          <!--仓库名称-->
          <b-col md="3">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="生产仓库"
                    label-for="warehouse_name"
                    label-size="sm"
                    class="mb-1 required"
            >
              <b-form-input
                      id="warehouse_name"
                      size="sm"
                      v-model="produceorder.warehouse_name"
                      @click="showFromWHModal()"
                      placeholder="请选择仓库"
                      readonly
              />
            </b-form-group>
          </b-col>



          <b-col md="3">
            <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="交货日期"
                    label-for="true_last_date"
                    label-size="sm"
                    class="mb-1 required"
            >
              <flat-pickr
                      v-model="produceorder.true_last_date"
                      class="form-control"
              />
            </b-form-group>
          </b-col>


        </b-row>
      </b-card>


      <!--成品明细-->
      <b-col md="12">
        <div class="inner-card-title">生产清单</div>
      </b-col>

      <b-col>
        <produce-order-complete-list ref="itemListComplete" :warehouse_id="produceorder.warehouse_id" :readonly="readonly"  :produce_id="produceorder.produce_id" :produce_no="produceorder.produce_no"   v-if="produceorder.produce_id > 0" v-on:table="fromChildrenOnNum()">
        </produce-order-complete-list>
      </b-col>
      <!--原料明细-->
      <div  v-if="statusItem==5||statusItem==6">
      <b-col md="12">
        <div class="inner-card-title">原料清单</div>
      </b-col>
      <produce-source-list  ref="itemList"  :readonly="readonly" :produce_id="produceorder.produce_id" :produce_no="produceorder.produce_no" :warehouse_id="produceorder.warehouse_id"  v-if="produceorder.produce_id > 0"  >
      </produce-source-list>
      </div>
      <!--保存按钮-->
      <b-col
              cols="12"
              class="mt-50"
      >
        <b-button
                variant="primary"
                class="mr-1"
                @click="save(1)"
                v-if="readonly===0"
        >
          保存
        </b-button>

        <b-button
                variant="outline-secondary"
                @click="cancel"
                class="mr-1"
        >
          取消
        </b-button>

        <b-button
            variant="primary"
            class="mr-1"
            @click="save(2)"
            v-if="readonly===0"
        >
          提交
        </b-button>
      </b-col>

    </b-form>
    <!--申请人-->
    <b-modal
          id="modal-single"
          ok-only
          ok-title="确认"
          @ok="onSelectPeople"
          cancel-title="取消"
          centered
          size="lg"
          :title="'请选择申请人'"
          ref="peopleModal"
  >
    <user-select
            ref="peopleSelect" v-on:table="fromChildren($event,'user')"
    >
    </user-select>
  </b-modal>

    <!--接收人-->
    <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectPeopleReceive"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择接收人'"
            ref="peopleModalReceive"
    >
      <user-select
              ref="peopleSelectReceive" v-on:table="fromChildrenReceive($event,'user')"
      >
      </user-select>
    </b-modal>
    <!--仓库-->
    <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectWHFromPeople"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择仓库'"
            ref="fromWHModal"
    >
      <WareHouse-Select
              ref="whSelect" v-on:table="fromChildren($event,'from')"
      >
      </WareHouse-Select>
    </b-modal>
    <!--/ form -->
   <!-- </validation-observer>-->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import produceorderStore from './produceorderStore'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,second } from '@core/utils/filter'
import ProduceOrderSourceList from '@/views/apps/produceordersource/ProduceOrderSourceList'
import ProduceOrderCompleteList from '@/views/apps/produceordercomplete/ProduceOrderCompleteList'
import ProduceSourceList from '@/views/apps/produceordersource/ProduceSourceList'
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    UserSelect,
    WareHouseSelect,
    ProduceOrderCompleteList,
    ProduceSourceList,
  },
  data() {
    return {
      id: 0,
      produceorder: {},
      readonly:0,
      warehouse_id:'',
      produce_id:'',
      produce_no:'',
      itemListShowFlag: true,
      istrue:false,
      statusItem:0,
    }
  },
  methods:{
    showPeopleModal(){
      this.$refs['peopleModal'].show()
    },
    showPeopleModalReceive(){
      this.$refs['peopleModalReceive'].show()
    },
    onSelectPeople() {
      let returnData = {}
      if(this.$refs.peopleSelect.getSelected().length > 0){
        returnData = this.$refs.peopleSelect.getSelected()[0]
        this.produceorder.applier_id = returnData.user_id
        this.produceorder.applier_name = returnData.full_name
        this.$forceUpdate()
      }
    },
    onSelectPeopleReceive() {
      let returnData = {}
      if(this.$refs.peopleSelectReceive.getSelected().length > 0){
        returnData = this.$refs.peopleSelectReceive.getSelected()[0]
        this.produceorder.receive_id = returnData.user_id
        this.produceorder.receive_name = returnData.full_name
        this.$forceUpdate()
      }
    },
    showFromWHModal(){
      this.$refs['fromWHModal'].show()
    },
    onSelectWHFromPeople(){
      let returnData = {}
      if(this.$refs.whSelect.getSelected().length > 0){
        returnData = this.$refs.whSelect.getSelected()[0]
        //判斷是否已添加材料明细
        this.produceorder.warehouse_id = returnData.id
        this.produceorder.warehouse_name = returnData.warehouse_name


        this.$forceUpdate()
      }
    },
  },
  setup() {
    const toast = useToast()
   // Register module
    if (!store.hasModule('produceorder')) store.registerModule('produceorder', produceorderStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceorder')) store.unregisterModule('produceorder')
    })

    const edit = function() {
      this.itemListShowFlag = true
      store.dispatch('produceorder/edit', {id: this.id}).then(res => {
        this.produceorder = res.data.data
        this.produceorder.start_date = toDate(this.produceorder.start_date)
        this.produceorder.true_last_date = toDate(this.produceorder.true_last_date)
      })
    }

    const view = function() {
      store.dispatch('produceorder/view', {id: this.id}).then(res => {
        this.produceorder = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function(result) {
    /*  let myitem = this.$refs.itemList.datalist
      let length = myitem.length*/
      //至少填写一个成品
      let myItemComplete = this.$refs.itemListComplete.datalist
      if(myItemComplete.length<1){
        toast.error("至少一个成品")
        return false
      }
      //判断是否选择了仓库`9
      if(this.produceorder.warehouse_name==''||this.produceorder.warehouse_name==undefined){
        toast.error("请选择生产仓库")
        return false
      }

      if(this.produceorder.true_last_date==''||this.produceorder.true_last_date==undefined){
        toast.error("请选择交货日期")
        return false
      }

      //判断成品填写格式
      let qtyAll = 0;
      for(let  i = 0 ;i<myItemComplete.length;i++){
        let cost_price = myItemComplete[i].cost_price;
        let qty = myItemComplete[i].qty;
        qtyAll = parseInt(qtyAll)+parseInt(qty);
        let box_quantity_car = myItemComplete[i].box_quantity_car;
        if(cost_price==undefined){
           toast.error("成品必须填写成本价")
          return false
        }
        if(qty==undefined||qty==0){
          toast.error("计划数量填写错误")
          return false
        }
        if(box_quantity_car==undefined||box_quantity_car==0){
          toast.error("箱规填写错误")
          return false
        }
      }

      //
      if(myItemComplete.length>0){
        for(let j = 0 ;j<myItemComplete.length;j++){
          let item = myItemComplete[j];
          this.produceordercomplete = item
          store.dispatch('produceordercomplete/updateOrderItem', this.produceordercomplete
          )
              .then(res => {
                if (res.data.code==0){
                  console.log("数据已保存")
                }else {
                  toast.error(res.data.data)
                  return false
                }
              })
        }
      }
      this.produceorder.true_last_date = second(this.produceorder.true_last_date);
      this.produceorder.purchase_qty = qtyAll;
       store.dispatch('produceorder/save',{
           ...this.produceorder,
           result:result
           }
       ).then(res => {
         if (res.data.code==0){
           console.log("数据已保存")
           this.$router.push({ name: 'apps-produceorder-list'});
         }else {
           toast.error(res.data.data)
           return false
         }
           })
    }

    const fromChildren = function (data,modal) {
      if (modal=='user'){
        this.produceorder.applier_id = returnData.user_id
        this.allotoutbound.applier_name = returnData.full_name
        this.$forceUpdate()
      }
      if (modal=="from"){
        this.produceorder.warehouse_id = data.id
        this.produceorder.warehouse_name = data.warehouse_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    const fromChildrenReceive = function (data,modal) {
      if (modal=='user'){
        this.produceorder.receive_id = returnData.user_id
        this.allotoutbound.receive_name = returnData.full_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    const fromChildrenOnNum = function (val) {
      this.$bvModal.hide('modal-single')
    }

    return {
      edit,
      view,
      cancel,
      save,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      fromChildren,
      fromChildrenReceive,
      fromChildrenOnNum,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.readonly = this.$route.params.readonly || null;
    this.statusItem = this.$route.params.statusItem || 0;
    if (this.$route.params.readonly===0||this.id===0){
      this.readonly=0
    }
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
