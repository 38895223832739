import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function producesourceUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID' },
    { key: 'produce_id', label: '采购订单ID' },
    { key: 'produce_no', label: '生产单编号' },
    //{ key: 'is_main', label: '是否主原料', sortable: true },
    //{ key: 'stock_id', label: 'ID', sortable: true },
    //{ key: 'stock_no', label: '库存编号', sortable: true },
    { key: 'product_code', label: '69码' },
    //{ key: 'warehouse_id', label: '库存所在仓库ID', sortable: true },
    { key: 'warehouse_name', label: '库存所在仓库名称' },
    //{ key: 'stocklock_id', label: '锁库ID', sortable: true },
    //{ key: 'purchase_orderitem_id', label: '采购单产品明细ID', sortable: true },
    //{ key: 'product_id', label: '商品ID', sortable: true },
    { key: 'expiry_date', label: '产品批次' },
    //{ key: 'production_date', label: '生产日期', sortable: true },
    //{ key: 'status', label: '库存状态', sortable: true },
    { key: 'name', label: '名称' },
    //{ key: 'is_gift', label: '是否赠品', sortable: true },
    //{ key: 'specification', label: '商品规格', sortable: true },
    { key: 'product_unit', label: '基本单位' },
    //{ key: 'box_quantity', label: '单箱标准数量', sortable: true },
    //{ key: 'cost_price', label: '成本价', sortable: true },
    { key: 'stock_qty', label: '库存数量', sortable: true },
    //{ key: 'purchase_qty', label: '预计使用数量', sortable: true },
    { key: 'true_qty', label: '*实际使用数量' },
   // { key: 'inboundForm', label: '本次出库', sortable: true },
    //{ key: 'state', label: '状态', sortable: true },
    //{ key: 'add_time', label: '添加时间', sortable: true },
   // { key: 'modify_time', label: '修改时间', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  // const start = ref(1)
  // const limit = ref(10)
  // const limitOptions = [10, 25, 50, 100]
  // const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('produce_id')
  const isSortDirDesc = ref(true)

  // const dataMeta = computed(() => {
  //   const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
  //   return {
  //     from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
  //     to: limit.value * (start.value - 1) + localItemsCount,
  //     of: listTotals.value,
  //   }
  // })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('produceordersource/search', {
        search_key: searchKey.value,
        order_by: orderBy.value,
        produce_id:config.produce_id,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        callback(list)
      })
      .catch((e) => {
        console.log("异常："+e)
        toast({
          component: ToastificationContent,
          props: {
            title: '生产单原料列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    // limit,
    // start,
    // listTotals: listTotals,
    // dataMeta,
    // limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
