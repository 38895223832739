import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function produceordercompleteUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    //{ key: 'id', label: 'ID', sortable: true },
    //{ key: 'produce_id', label: '采购订单ID', sortable: true },
    //{ key: 'produce_no', label: '生产单编号', sortable: true },
    { key: 'product_id', label: '商品' },
    //{ key: 'name', label: '名称', sortable: true },
    { key: 'specification', label: '商品规格' },
    { key: 'product_unit', label: '基本单位' },
    //{ key: 'stockQty', label: '库存' },
   // { key: 'box_quantity', label: '单箱标准数量', sortable: true },
    { key: 'cost_price', label: '*含税成本'},
    { key: 'qty', label: '*计划数量' },
    { key: 'true_qty', label: '*生产数量' },
    { key: 'box_quantity_car', label: '*装车箱规'},
    { key: 'boxQty', label: '箱数' },
    { key: 'company_name', label: '公司名称' },
    { key: 'memo', label: '备注' },
    //{ key: 'state', label: '状态', sortable: true },
    //{ key: 'add_time', label: '添加时间', sortable: true },
    //{ key: 'modify_time', label: '修改时间', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  // const start = ref(1)
  // const limit = ref(10)
  // const limitOptions = [10, 25, 50, 100]
  // const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('produce_id')
  const isSortDirDesc = ref(true)

  // const dataMeta = computed(() => {
  //   const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
  //   return {
  //     from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
  //     to: limit.value * (start.value - 1) + localItemsCount,
  //     of: listTotals.value,
  //   }
  // })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([ searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('produceordercomplete/search', {
        produce_no:config.produce_no,
        produce_id:config.produce_id,
        search_key: searchKey.value,

        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.list
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '生产单成品列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    // limit,
    // start,
    // listTotals: listTotals,
    // dataMeta,
    // limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
