<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                      variant="primary"
                      @click="showselproduct()"
                      v-if="getCodeLabel('setting','start_inventory')!=='1'"
              >
                <span class="text-nowrap">添加产品</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        v-model="datalist"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          <b-link
              :to="{ name: 'apps-produceordercomplete-edit', query: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('produceordercomplete_type', data.item.produceordercomplete_type)}}
        </template>

        <template #cell(product_id)="data" >
          [#{{data.item.product_id}}]{{data.item.name}}
        </template>

        <template #cell(add_time)="data">
          {{toDate(data.item.add_time)}}
        </template>



        <template #cell(modify_time)="data">
          {{toDate(data.item.modify_time)}}
        </template>

        <template #cell(product_unit)="data">
          {{getCodeLabel('product_unit', data.item.product_unit)}}
        </template>

        <!--@change="costOrCostTax(0 ,'product_cost',data.index)" -->
        <template #cell(cost_price)="data">
          <div style="width: 70px">
            <ValidationProvider :rules="`required${data.item.is_gift === 1 ? '' : '|positive'}`" name="含税成本"
                                #default="{ errors }" :vid="`cost_price_${data.index}`">
              <b-form-input
                      size="sm"
                      type="number"
                      v-model="data.item.cost_price"

                      :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

          </div>
        </template>

        <template #cell(qty)="data">
          <div style="width: 70px">
              <b-form-input
                  size="sm"
                  type="number"
                  v-model="data.item.qty"
                  @change="checkQty(data.item)"
              />
          </div>
        </template>

        <template #cell(box_quantity_car)="data">
          <div style="width: 70px">
            <b-form-input
                id="box_quantity_car"
                size="sm"
                v-model="data.item.box_quantity_car"
                @change="checkQtyBox(data.item)"
                type="number"
            />
          </div>
        </template>

        <template #cell(boxQty)="data">
          {{data.item.ext['boxQty']}}
        </template>

        <template #cell(stockQty)="data">
          {{data.item.ext['stockQty']}}
        </template>

<!--
        <template #cell(boxQty)="data">
          {{ (data.item.qty/data.item.box_quantity_car).toFixed(1)}}
        </template>
-->



        <template #cell(memo)="data" >
          <b-form-input
              size="sm"
              v-model="data.item.memo"
              style="width: 130px; float: left"
          >
          </b-form-input>
        </template>

        <template #cell(company_name)="data">
          <b-form-input
              size="sm"
              v-model="data.item.company_name"
              style="width: 130px; float: left"
          >
          </b-form-input>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data" v-if="readonly==0">
          <b-link @click="del(data.item.item_id,data.index)">
            <feather-icon
                    icon="XSquareIcon"
                    size="16"
                    class="align-middle text-body"
            />
          </b-link>
        </template>

      </b-table>
<!--      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          &lt;!&ndash; Pagination &ndash;&gt;
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>-->
      <b-modal
              id="productModal"
              ok-only
              ok-title="确认"
              @ok="onSelectProduct"
              cancel-title="取消"
              centered
              size="xl"
              :title="'请选择产品'"
      >
        <product-select
                ref="productSelect"
        >
        </product-select>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import produceordercompleteUseList from './produceordercompleteUseList'
import produceordercompleteStore from './produceordercompleteStore'
import productStore from '../product/productStore'
import ProductSelect from '@/views/apps/product/product-produce/ProductSelect'
import {useToast} from 'vue-toastification/composition'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ProductSelect,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('produceordercomplete/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    costOrCostTax(errors, flag, index) {
      if (errors === 0) {
        this.dataList[index][flag] = 0
        //this.calTotal(index)
      }
    },
  },
  data() {
    return {
      datalist:[],
    }
  },
  props: {
    produce_id: {
      type: Number,
      default: 0
    },
    produce_no: {
      type: String,
      default: ""
    },
    readonly:{
      type: Number,
      default: 0
    },
    warehouse_id:{
      type:Number,
      default:0
    }
  },
  setup(props) {
    // Register module
    const toast = useToast()
    const readonly = props.readonly
    if (!store.hasModule('produceordercomplete')) store.registerModule('produceordercomplete', produceordercompleteStore)
    if (!store.hasModule('product')) store.registerModule('product', productStore)


    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('produceordercomplete')) store.unregisterModule('produceordercomplete')
      if (store.hasModule('product')) store.unregisterModule('product')
    })

    const showselproduct = function(){
      if(props.warehouse_id==0||props.warehouse_id==undefined){
        toast.error("请先选择生产仓库，再添加产品！")
        return false
      }
      this.$bvModal.show('productModal')
      this.produce_id = props.produce_id
      this.produce_no = props.produce_no

    }

    const onSelectProduct= function (){
      let selected = this.$refs.productSelect.getSelected();
      if(selected.length > 0){
        let product_id = selected[0]['product_id']
        store.dispatch('produceordercomplete/addnewpro', {warehouseId:props.warehouse_id,produceid: this.produce_id, productid: product_id,produceno:this.produce_no}).then(res => {
          if(res.data.code == 0){
            refetchData()
          }else{
            toast.error(res.data.data)
          }
        })
      }
    }

    const del = async function (item_id,index) {
      let res = confirm('确定删除吗？')
      if (res) {
        const res = await store.dispatch('produceordercomplete/del', {
          id: item_id
        })
        if (res.data.code === 0) {
          toast.success(res.data.data)
          refetchData()
          const temp = this.dataList
          temp.splice(index,1)
          this.$emit('table', temp)
        } else {
          toast.error(res.data.data)
        }
      }

    }

    const save = function (item) {
      store.dispatch('produceordercomplete/updateOrderItem', {
        itemid: item.item_id,
        //含税成本
        productcost: parseFloat(item.cost_price),
      })
              .then(res => {
                if (res.data.code==0){
                  toast.success('数据已保存!')
                  refetchData()
                }else {
                  toast.error(res.data.data)
                }

              })
    }

    const checkQtyBox = function (item) {
      if(item.qty>0){
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.box_quantity_car)) {
        toast.error('装车箱规必须是正整数!')
        item.box_quantity_car = 1
        item.ext['boxQty'] =item.qty;
      }else{
        item.ext['boxQty'] =(item.qty/item.box_quantity_car).toFixed(1);
      }
      } else {
        toast.error('请先填写计划数量再填写装车箱规!')
        item.box_quantity_car = 1
        return false
      }

    }

    const checkQty = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.qty)) {
        toast.error('计划数量必须是正整数!')
        item.qty = 0
      }

    }

    const {
      searchList,
      tableColumns,
     /* limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,*/
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = produceordercompleteUseList(
            {produce_id:props.produce_id ,
             produce_no:props.produce_no
            }
    )

    return {
      searchList,
      del,
      save,
      tableColumns,
     /* limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,*/
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      showselproduct,
      onSelectProduct,
      // UI

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      checkQty,
      checkQtyBox
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
